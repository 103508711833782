export default function date() {
	let months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	let days = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	]

	let now = new Date()
	let thisMonth = months[now.getMonth()] // getMonth returns the month of the date (0-January :: 11-December)
	let thisYear = now.getFullYear()
	let thisDay = days[now.getDay()]

	let futureDate = new Date(now)
	futureDate.setDate(now.getDate() + 7) // Setting the date to one week in the future
	let futureDay = days[futureDate.getDay()]
	let futureMonth = months[futureDate.getMonth()]
	let futureDateNum = futureDate.getDate()

	// Update month and year elements
	let monthEls = document.querySelectorAll('.dyn-month')
	let yearEls = document.querySelectorAll('.dyn-year')

	monthEls.forEach(function (el) {
		el.textContent = thisMonth
	})

	yearEls.forEach(function (el) {
		el.textContent = thisYear
	})

	// Update elements with class '.7-days' to show the date one week in the future
	let futureEls = document.querySelectorAll('.dyn-week')
	futureEls.forEach(function (el) {
		el.textContent = `${futureMonth} ${futureDateNum}`
	})
}
